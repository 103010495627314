import Image from 'next/image'
import React, { FC, useCallback, useState } from 'react'

import { useSelector } from 'react-redux'

import { Box, CardMedia, IconButton } from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { Skeleton } from '@material-ui/lab'

import EmpriseGroup from '~/services/EmpriseGroup'
import { isLogged } from '~/state/modules/user'

import { getEntityByBrand } from '~/brands'

import LogoBoat from '~/../public/static/images/logo.boat.png'
import LogoRv from '~/../public/static/images/logo.rv.png'

const logoSrc = getEntityByBrand<string>({ rv: LogoRv, boat: LogoBoat })

interface ProductMediaProps {
  cover: string
  style?: React.CSSProperties
  featured?: boolean
  favorite: boolean
  onFavorite: (favorite: boolean) => void
  onRemoveFavorite?: (favorite: boolean) => void
  alt: string
  loading?: boolean
  isReload?: boolean
  productId: string | number
  variant: 'grid' | 'list'
  className?: string
  isPremium?: boolean
  isPlus?: boolean
}

const ProductMedia: FC<ProductMediaProps> = ({
  alt,
  cover,
  children,
  style,
  featured,
  favorite,
  onFavorite,
  loading,
  productId,
  variant,
  isPlus,
  isPremium,
  className
}) => {
  const logged = useSelector(isLogged)
  const [isFavorite, setFavorite] = useState(favorite)
  const [disable, setDisabled] = useState(false)

  const handlefavoriteClick = useCallback(
    async (e) => {
      e.stopPropagation()
      e.preventDefault()
      try {
        setDisabled(true)
        await EmpriseGroup.setFavoriteListing(+productId, isFavorite)

        if (onFavorite) {
          onFavorite(!isFavorite)
        }

        setFavorite(!isFavorite)
      } catch (e) {
        console.log('e', e)
      } finally {
        setDisabled(false)
      }
    },
    [isFavorite]
  )

  const isShowLabel = featured || isPremium || isPlus

  const isShowList = !cover || variant === 'list'

  const listingLabel = () => {
    if (isPremium && !featured) return 'Premium'

    if (isPlus && !featured) return 'Plus'

    return 'Featured'
  }

  return (
    <CardMedia
      className={className}
      style={{ position: 'relative', overflow: 'hidden', ...style }}
    >
      {loading ? (
        <Skeleton variant="rect" width="100%" height="100%" />
      ) : (
        <>
          {isShowLabel && (
            <Box
              position="absolute"
              left={10}
              top={10}
              borderRadius={4}
              px={1}
              py={0.5}
              zIndex={1}
              display="inline-flex"
              color="common.white"
              bgcolor="primary.main"
              style={{ textTransform: 'uppercase' }}
            >
              {listingLabel()}
            </Box>
          )}
          {logged && (
            <>
              <Box
                right={10}
                position="absolute"
                top={10}
                borderRadius="50%"
                bgcolor="common.white"
                zIndex={1}
              >
                <IconButton
                  size="small"
                  disabled={disable}
                  onClick={handlefavoriteClick}
                >
                  {isFavorite ? (
                    <FavoriteIcon color="primary" />
                  ) : (
                    <FavoriteBorderIcon color="primary" />
                  )}
                </IconButton>
              </Box>
            </>
          )}
          <div
            style={{
              overflow: 'hidden',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              ...(isShowList && {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              })
            }}
          >
            {cover ? (
              <Image
                alt={alt}
                title={alt}
                src={cover}
                layout="fill"
                height={350}
                objectFit={cover ? 'cover' : 'contain'}
                objectPosition="center"
                sizes="(max-width: 600px) 200px, 400px"
              />
            ) : (
              <Image
                alt={alt}
                title={alt}
                src={logoSrc}
                layout="fixed"
                width={220}
                height={76}
              />
            )}
          </div>
          {children}
        </>
      )}
    </CardMedia>
  )
}

export default ProductMedia
